<template>
  <div class="preview-tab">
    <div class="custom-card">
      <div>
        <b-alert variant="primary" show>
          <span class="custom-text">
            This is a preview of how your featured endorsements will be
            displayed. Your non-featured endorsements will be displayed below
            this block
          </span>
        </b-alert>
      </div>
      <div :class="`text-${layout}`">
        <p id="we-endorse">
          We Endorse <span>{{ candidate.name }}</span>.
        </p>
        <div class="featured-endorsements" :class="layout">
          <div class="featured-group"
               v-for="(item, i) in endorsementGroups"
               :key="i">
            <div v-if="checkStatement(statementId, item.items)">
              <h4 v-if="checkTitle(item.items)" class="group-title">
                {{ item.title }}
              </h4>
              <b-row class="group-names">
                <b-col cols="12"
                       sm="6"
                       lg="4"
                       v-for="(member, i) in item.items"
                       :key="i"
                       class="list-item">
                  <p>
                    {{
                      `${member.publicEndorsement.firstName} ${member.publicEndorsement.lastName}`
                    }}
                  </p>
                  <span class="key-color key-orange"
                        v-if="colorCheck(1, member.publicEndorsement.position)"></span>
                  <span class="key-color key-red"
                        v-if="colorCheck(2, member.publicEndorsement.position)"></span>
                  <span class="key-color key-green"
                        v-if="colorCheck(3, member.publicEndorsement.position)"></span>
                  <span class="key-color key-yellow"
                        v-if="colorCheck(4, member.publicEndorsement.position)"></span>
                  <span class="key-color key-purple"
                        v-if="colorCheck(5, member.publicEndorsement.position)"></span>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <b-row>
          <b-col>
            <div>
              <b-button variant="outline-secondary"
                        align-self="left"
                        size="lg"
                        @click="$emit('prev')">
                <b-icon icon="arrow-left"></b-icon>
                Back
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
  import PreviewTile from "@/components/admin/ManageEndorsements/PreviewTile.vue";
  export default {
    props: {
     statementId: {
       required: true,
     },
   },
   mounted(){
   },
  computed: {
    endorsementGroups() {
      return this.$store.state.candidates.candidate.endorsementGroups;
    },
    layout() {
      var layout = this.$store.state.candidates.candidate.endorsementLayout;

        if (layout == 0) return "left-layout";

        return "center-layout";
      },
      candidate() {
        return this.$store.state.candidates.candidate
      }
    },
    methods: {
      checkStatement(statementId, groupItems) {
        var data = groupItems.filter(function (item) {
          return item.endorsementStatementId == statementId;
        });
        if (data.length > 0) {
          return true;
        } else {
          return false;
        }
      },
      checkTitle(groupItems) {
        var data = groupItems.filter(function (item) {
          return item.endorsementGroupId == 0;
        });
        if (data.length > 0) return false;
        return true;
      },
      colorCheck(id, positions) {
        let pos = positions.split(",");
        pos = pos.map(x => {
          return parseInt(x);
        });
        return pos.some(x => x === id);
      }
    }
  };
</script>

<style lang="scss">

  .preview-tab {
    #we-endorse {
      font-weight: 600;
      margin-top: 40px;
    }

    .endorsements-list {
      p {
        margin-bottom: 0;
        line-height: 22px;
      }

      .list-item {
        min-height: 53px;
        border-bottom: 1px solid #bebebe7d;
        padding-bottom: 10px;
        margin-bottom: 10px;
      }
    }

    .featured-endorsements {
      margin-top: 40px;
      margin-bottom: 40px;

      p {
        margin-bottom: 0;
        font-weight: 600;
        font-size: 20px;
      }

      .featured-group {
        margin-bottom: 40px;

        .group-title {
          font-size: 24px;
          font-weight: 900;
          margin-bottom: 20px;
        }

        .group-names {
          .list-item {
            padding-bottom: 15px;
          }
        }
      }

      &.center-layout {
        text-align: center;

        .featured-group {
          .row {
            justify-content: center;
          }
        }
      }
    }
  }
</style>
